/** @jsx jsx */
import { jsx } from 'theme-ui';

import CoverImage from 'gatsby-theme-chronoblog/src/components/cover-image';
import Date from 'gatsby-theme-chronoblog/src/components/date';
import Tags from 'gatsby-theme-chronoblog/src/components/tags';
import CardComponents from 'gatsby-theme-chronoblog/src/components/feed-items/card/card-components';

const Card = CardComponents;

export default ({ item, isHovering, linksBeforeTitle = '' }) => {
  return (
    <article sx={{ mb: '40px', mt: '20px', color: 'text' }}>
      <Card.HoveringStyle isHovering={isHovering}>
        <Card.Link item={item}>
          <CoverImage data={item} type="card" />
        </Card.Link>
        <Card.Body item={item}>
          <Card.Link item={item}>
            <div sx={{ pt: ['10px', '20px'], mb: 2 }}>
              <Card.Title item={item} linksBeforeTitle={linksBeforeTitle} />
              <Card.LinkText item={item} />
              {item.fields.type !== 'links' && (
                <Date
                  date={item.frontmatter.date}
                />
              )}
            </div>
          </Card.Link>
          <Card.Content item={item} />
          <Card.Link item={item}>
            <Card.ReadMoreButton item={item} />
          </Card.Link>
        </Card.Body>
      </Card.HoveringStyle>
    </article>
  );
};
