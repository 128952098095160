/** @jsx jsx */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { useEffect, useState } from 'react';
import { Box, Container, Flex, jsx, Styled } from 'theme-ui';
import validator from 'validator';
import { SectionHeading } from './section-heading';
import Button from './subscription-button';
import { getItem, setItem } from '../utils';

const EmailIcon = () => {
  return (
    <div
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: ['40px'],
        minWidth: ['40px']
      }}
    >
      <div
        sx={{
          fontSize: ['26px'],
          opacity: ['0.7'],
          userSelect: 'none'
        }}
      >
        <span role="img" aria-label="email">
          <FontAwesomeIcon icon="envelope" />
        </span>
      </div>
    </div>
  );
};

export const SubscriptionForm = () => {
  const [email, setEmail] = useState('');
  const [responseMessage, setResponseMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const SUBSCRIBED_KEY = 'subscribed';
  const SUCCESS_RESULT = 'success';
  const defaultErrorMessage = 'Something went wrong, please try again later';

  useEffect(() => {
    const subscribed = getItem(SUBSCRIBED_KEY);
    if (subscribed && subscribed === 'true') {
      setIsSubscribed(true);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (!email) return setErrorMessage('Please type your email address');
      if (!validator.isEmail(email)) {
        return setErrorMessage('Email address is not valid, please try again');
      }
      const isDevEnvironment = process.env.NODE_ENV === 'development';
      const response = !isDevEnvironment
        ? await addToMailchimp(email)
        : { result: SUCCESS_RESULT };
      if (response.result !== SUCCESS_RESULT) {
        console.error(response.msg);
        setErrorMessage(defaultErrorMessage);
        return;
      }

      setIsSubscribed(true);
      setResponseMessage(response.result);
      setItem(SUBSCRIBED_KEY, true);
      if (!isDevEnvironment) {
        trackCustomEvent({
          category: 'Newsletter subscription',
          action: 'Click',
          label: 'Subscribed'
        });
      }
    } catch (error) {
      console.error('subscription failed', error);
      setErrorMessage(defaultErrorMessage);
    }
  };

  const onChange = (event) => {
    setEmail(event.target.value);
    setErrorMessage(null);
  };

  return (
    <div sx={{ marginY: [20] }}>
      {isSubscribed && responseMessage === SUCCESS_RESULT && (
        <Styled.h3 style={{ textAlign: 'center', marginTop: '3rem' }}>
          Thank you for subscribing!
        </Styled.h3>
      )}
      {!isSubscribed && (
        <Box
          as="section"
          sx={{
            borderColor: '#444',
            borderStyle: 'solid',
            borderWidth: `search`,
            borderRadius: 'search'
          }}
        >
          <Container>
            <SectionHeading
              title="Join the newsletter"
              description="Subscribe to stay up to date with the latest content"
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Flex
                as="form"
                onSubmit={handleSubmit}
                sx={{
                  color: 'inherit',
                  bg: 'muted',
                  alignItems: 'center',
                  borderRadius: 'search',
                  borderColor: 'muted',
                  borderStyle: 'solid',
                  borderWidth: `1px`
                }}
              >
                <EmailIcon />
                <Box>
                  <input
                    type="email"
                    aria-label="email"
                    sx={{
                      '-webkit-appearance': 'textfield',
                      width: ['100%'],
                      color: 'inherit',
                      bg: 'background',
                      py: [2],
                      px: [2],
                      fontSize: [4],
                      fontFamily: 'inherit',
                      borderColor: 'muted',
                      borderStyle: 'solid',
                      borderWidth: `search`,
                      borderRadius: 'search',
                      outline: '0px solid',
                      opacity: '0.9'
                    }}
                    placeholder="Your email address"
                    value={email}
                    onChange={onChange}
                    onSubmit={handleSubmit}
                  />
                </Box>
                <Button variant="active" onClick={handleSubmit}>
                  <span
                    style={{
                      margin: 'auto',
                      padding: '0px',
                      lineHeight: '1rem'
                    }}
                  >
                    Subscribe
                  </span>
                </Button>
              </Flex>
            </Box>
            {errorMessage && (
              <Styled.p style={{ textAlign: 'center' }}>
                {errorMessage}
              </Styled.p>
            )}
          </Container>
        </Box>
      )}
    </div>
  );
};
