/** @jsx jsx */
import { jsx } from 'theme-ui';

import useSiteMetadata from 'gatsby-theme-chronoblog/src/hooks/use-site-metadata';

/**
 * @param {Date | string | undefined} date
 * @param {string} language
 * @param {object} options
 * @returns {string | undefined}
 */
const makeDate = (date, language, options, isUpdatedAt) => {
  if (!date) return undefined;
  const formattedDate = isUpdatedAt ? date.slice(0, -6).replace(' ', 'T') : date.replace(' UTC', '').replace(' ', 'T');
  const newDate = new Date(formattedDate);
  const dateString = newDate.toLocaleString(language, options);
  return dateString;
};

export default ({
  date,
  options = { year: 'numeric', month: 'long', day: 'numeric' },
  fontSize = [1],
  isUpdatedAt,
  ...props
}) => {
  const meta = useSiteMetadata();
  const dateString = makeDate(date, meta.siteLanguage, options, isUpdatedAt);
  if (dateString)
    //
    return (
      <span
        sx={{
          color: 'text',
          fontSize,
          opacity: 0.8,
          // fontStyle: 'italic',
          fontWeight: 'normal'
        }}
        {...props}
      >
        {dateString}
      </span>
    );
  return <div />;
};
