import React from 'react';
import { Box, Styled } from 'theme-ui';

const styles = {
  heading: {
    textAlign: 'center',
    maxWidth: 580,
    margin: '0 auto 25px'
  },
  title: {
    fontWeight: 800
  },
  description: {
    fontWeight: 800
  }
};

export const SectionHeading = ({ title, description }) => {
  return (
    <Box style={styles.heading}>
      <Styled.h3 style={styles.title}>{title}</Styled.h3>
      <Styled.p style={styles.description}>{description}</Styled.p>
    </Box>
  );
};
